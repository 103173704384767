import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import MobileSwiper from '../modules/mobile-swiper'

export default class FrontPage extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter());
    })
  }

  onEnter() {
    this.DOM = {
      pageHeaderVideo: viewStorage.current.querySelector('video'),
      featuredEventsSwiper: viewStorage.current.querySelector('.PageHeaderHome .swiper'),
      relatedEventsSwiper: viewStorage.current.querySelector('.RelatedEvents .swiper'),
      ourResourcesSwiper: viewStorage.current.querySelector('.OurResources .swiper.--mobile')
    }

    if (this.DOM.pageHeaderVideo) this.setVideoLazyload()
    if (this.DOM.featuredEventsSwiper) this.featuredEventsSwiperModule = new WknSwiper(this.DOM.featuredEventsSwiper, { slidesPerView: 1, spaceBetween: 0 })
    if (this.DOM.relatedEventsSwiper) this.relatedEventsSwiperModule = new WknSwiper(this.DOM.relatedEventsSwiper, { slidesPerView: 'auto', spaceBetween: 13 })
    if (this.DOM.ourResourcesSwiper) this.ourResourcesSwiperModule = new MobileSwiper(this.DOM.ourResourcesSwiper)
  }

  setVideoLazyload() {
    const videoSource = this.DOM.pageHeaderVideo.children[0]

    videoSource.src = videoSource.dataset.src

    this.DOM.pageHeaderVideo.load()
  }

  resetVideoLazyLoad() {
    const videoSource = this.DOM.pageHeaderVideo.children[0]

    videoSource.removeAttribute('src')

    this.DOM.pageHeaderVideo.pause()
    this.DOM.pageHeaderVideo.currentTime = 0
  }

  onLeaveCompleted() {
    if (this.DOM.pageHeaderVideo) this.resetVideoLazyLoad()
    if (this.featuredEventsSwiperModule) this.featuredEventsSwiperModule.destroy()
    if (this.relatedEventsSwiperModule) this.relatedEventsSwiperModule.destroy()
    if (this.ourResourcesSwiperModule) this.ourResourcesSwiperModule.destroy()
  }
}
