import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class HistoryTimeline {
  constructor({ sections }) {
    if (!sections && !sections.length) return

    // DOM
    this.DOM = { sections }

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    // Events
    this.DOM.sections.forEach((section, index) => this.createAnimation(section, index))
  }

  createAnimation(section, index) {
    const title = section.querySelector('.title')
    const events = section.querySelectorAll('.TimelineEvent')

    // Animations only available on desktop
    this.mm.add(this.responsiveObj, context => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        // Parallax for each section date title
        section.titleTween = gsap.fromTo(title, {
          xPercent: index % 2 === 0 ? 10 : -10,
        }, {
          xPercent: index % 2 === 0 ? -15 : 15,
          ease: 'none',
          scrollTrigger: {
            trigger: section,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true
          }
        })

        // Parallax for each event image
        events.forEach((ev, i) => {
          const type = ev.dataset.type
          const image = ev.querySelector('.visual__container')

          if (!image) return

          gsap.from(image, {
            scale: 0.7,
            yPercent: 100,
            xPercent: type === 'image_left' ? -15 : 15,
            rotate: type === 'image_left' ? -15 : 15,
            ease: 'none',
            scrollTrigger: {
              trigger: ev,
              start: 'top bottom',
              end: 'bottom top',
              scrub: true
            }
          })
        })
      }
    })
  }

  destroy() {
    // Destroy all GSAP tweens
    if (this.mm) this.mm.revert()
  }
}
