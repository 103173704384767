/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onLeave, onEnter, onEnterCompleted } from './_events'

/* --- Renderers --- */
import ArchiveResources from './pages/archive-app_resource'
import ArchiveEvents from './pages/archive-app_event'
import FrontPage from './pages/front-page'
import PageContact from './pages/page-contact'
import PageHistory from './pages/page-history'
import PageMonastery from './pages/page-monastery'
import PageVisitMonastery from './pages/page-visit-monastery'
import PageVocation from './pages/page-vocation'
import Page from './pages/page'
import Single from './pages/single'
import SingleEvent from './pages/single-app_event'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item):not(.woocommerce-MyAccount-downloads-file)',
  renderers: {
    default: Page,

    archiveResources: ArchiveResources,
    archiveEvents: ArchiveEvents,
    frontPage: FrontPage,
    pageContact: PageContact,
    pageHistory: PageHistory,
    pageMonastery: PageMonastery,
    pageVisitMonastery: PageVisitMonastery,
    pageVocation: PageVocation,

    single: Single,
    singleEvent: SingleEvent,
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
