import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from './wkn-swiper'

export default class RelatedResourcesSwipers {
  constructor() {
    this.DOM = {
      sectionSwipers: viewStorage.current.querySelectorAll('.RelatedResources .swiper')
    }

    if (!this.DOM.sectionSwipers && !this.DOM.sectionSwipers.length) return

    this.swipers = []

    this.DOM.sectionSwipers.forEach(s => {
      this.swipers.push(new WknSwiper(s, { slidesPerView: 'auto', spaceBetween: 13 }))
    })
  }

  destroy() {
    if (this.swipers && this.swipers.length) {
      this.swipers.forEach(swiper => swiper.destroy())
    }
  }
}
