import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'

export default class ArchiveResources extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter());
    })
  }

  onEnter() {
    this.DOM = {
      form: viewStorage.current.querySelector('.FilterForm'),
      checkboxes: viewStorage.current.querySelectorAll('.FilterForm input[type="checkbox"]'),
      allCheckbox: viewStorage.current.querySelector('.FilterForm input[type="checkbox"]#all'),
      filterSelect: viewStorage.current.querySelector('.FilterForm select'),
    }
    this.initForm()
  }

  initForm() {
    this.DOM.checkboxes && this.DOM.checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', () => this.onCheckboxChange(checkbox))
    })
    this.DOM.filterSelect && this.DOM.filterSelect.addEventListener('change', () => this.onSelectChange())
  }



  onCheckboxChange (checkbox) {
    if (checkbox.id === 'all') {
      this.DOM.checkboxes.forEach(checkbox => checkbox.checked = false)
      checkbox.checked = true
    } else {
      this.DOM.allCheckbox.checked = false
    }
    this.DOM.form.submit()
  }

  onSelectChange () {
    this.DOM.form.submit()
  }

  onLeaveCompleted() {
    this.DOM.checkboxes && this.DOM.checkboxes.forEach(checkbox => checkbox.removeEventListener('change', () => this.onCheckboxChange(checkbox)))
    this.DOM.filterSelect && this.DOM.filterSelect.removeEventListener('change', () => this.onSelectChange())
  }
}
