import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import HistoryTimeline from '../animations/history-timeline'

export default class PageContact extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter());
    })
  }

  onEnter() {
    this.DOM = {
    }
  }

  onLeaveCompleted() {
  }
}
