/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage, modulesStorage, animationsStorage } from './_globals'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

import { updateHeaderTheme } from './utils'
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'

import RelatedEventsSwipers from './modules/related-events-swipers'
import RelatedResourcesSwipers from './modules/related-resources-swipers'

import Heritage from './animations/heritage'
import { FormsManager } from '@wokine-agency/forms'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {
  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(domStorage.body)
  viewStorage.viewInView = new viewInView(domStorage.body)

  // Intro
  GlobalIntro()

  // General modules
  modulesStorage.related_events_swiper = new RelatedEventsSwipers()
  modulesStorage.related_resources_swiper = new RelatedResourcesSwipers()

  // General animations
  animationsStorage.heritage = new Heritage()

  // Forms
  initForms({
    DOM: document.DOM
  })

  // Update header theme
  updateHeaderTheme(viewStorage.current)
}

export const onEnter = (to, trigger) => {
  // ScrollTop
  // window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  domStorage.body.classList.remove('--loading')

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()

  // Forms
  initForms({
    DOM: viewStorage.current
  })

  // Update header theme
  updateHeaderTheme(viewStorage.current)
}

export const onEnterCompleted = (to, from, trigger) => {
  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': globalStorage.taxi.targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': globalStorage.taxi.targetLocation.href
    })
  }

  // Anchor
  if (globalStorage.taxi.targetLocation.hasHash) {
    if (document.querySelector(window.location.hash)) {
      gsap.to(window, {
        scrollTo: {
          y: window.location.hash,
          autoKill: false
        },
        overwrite: 'all',
        ease: 'expo.inOut',
        duration: 1.8,
      })
    }
  }

  // PageHeaderWithNav links
  const pageHeaderWithNavLinks = viewStorage.current.querySelectorAll('.PageHeaderWithNav nav li a')

  if (pageHeaderWithNavLinks && pageHeaderWithNavLinks.length) {
    pageHeaderWithNavLinks.forEach((link) => {
      if (link.href === globalStorage.taxi.targetLocation.raw) {
        link.classList.add('--active')
      } else {
        link.classList.remove('--active')
      }
    })
  }

  // General modules
  modulesStorage.related_events_swiper = new RelatedEventsSwipers()
  modulesStorage.related_resources_swiper = new RelatedResourcesSwipers()

  // General animations
  animationsStorage.heritage = new Heritage()
}

export const onLeave = (from, trigger) => {
  if (viewStorage.hasInview) viewStorage.viewInView.destroy()
  if (viewStorage.hasPrllx)  viewStorage.viewPrllx.destroy()

  domStorage.body.classList.add('--loading')
  domStorage.body.classList.add('--animating')

  // Close Menu
  if (globalStorage.menuOpen) globalStorage.closeMobileMenu()

  // Remove active links
  const navLinks = domStorage.menu.querySelectorAll('.Nav .Link')

  navLinks.forEach((link) => {
    if (link.href === globalStorage.taxi.targetLocation.raw) {
      link.classList.add('--active')
    } else {
      link.classList.remove('--active')
    }
  })

  // General modules
  if (modulesStorage.related_events_swiper) modulesStorage.related_events_swiper.destroy()
  if (modulesStorage.related_resources_swiper) modulesStorage.related_resources_swiper.destroy()

  // General animations
  if (animationsStorage.heritage) animationsStorage.heritage.destroy()
}
function initForms({DOM}){
  isDEVMODE && console.log('initForms')
  FormsManager.init({
    debug: isDEVMODE,
    DOM,
    clearOnSuccess: false,
    forms: {
      contact:{
        events: {
          onInit: function (form) {
            isDEVMODE && console.log('contact onInit', form)
          }
        },
      },
      newsletter:{
        events: {
          onInit: function (form) {
            isDEVMODE && console.log('newsletter onInit', form)
          }
        },
      }
    }
  });
}
