import { viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Heritage {
  constructor() {
    // DOM 
    this.DOM = {
      section: viewStorage.current.querySelector('.Heritage')
    }

    if (!this.DOM.section) return

    this.DOM.head = this.DOM.section.querySelector('.container > .head')
    this.DOM.cards = this.DOM.section.querySelectorAll('.HeritageCard')

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setAnimation()
  }

  setAnimation() {
    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        // Head animation on scroll
        gsap.to(this.DOM.head, {
          opacity: 0,
          scale: 0.65,
          yPercent: 100,
          ease: 'none',
          scrollTrigger: {
            trigger: this.DOM.section,
            endTrigger: this.DOM.head,
            start: 'top top',
            end: 'bottom top',
            scrub: true
          }
        })

        // Animation on each card
        this.DOM.cards.forEach((card, index) => {
          gsap.from(card, {
            scale: 0.75,
            // rotate: () => {
            //   if ((index % 3) === 0) {
            //     // 3n+1 (index 0, 3, 6, ...)
            //     return -2
            //   } else if ((index % 3) === 1) {
            //     // 3n+2 (index 1, 4, 7, ...)
            //     return 4
            //   } else if ((index % 3) === 2) {
            //     // 3n+3 (index 2, 5, 8, ...)
            //     return -3
            //   }
            // },
            yPercent: () => {
              if ((index % 3) === 0) {
                // 3n+1 (index 0, 3, 6, ...)
                return 20
              } else if ((index % 3) === 1) {
                // 3n+2 (index 1, 4, 7, ...)
                return 15
              } else if ((index % 3) === 2) {
                // 3n+3 (index 2, 5, 8, ...)
                return 10
              }
            },
            ease: 'none',
            scrollTrigger: {
              trigger: card,
              start: 'top bottom',
              end: 'bottom top',
              scrub: true
            }
          })
        })
      }
    })
  }

  destroy() {
    if (this.mm) {
      this.mm.revert()
    }
  }
}