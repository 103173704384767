import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

// import { homeIntro } from './home-intro'
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  // Update body classNames
  domStorage.body.classList.remove('--preloading')
  domStorage.body.classList.remove('--loading')

  // Reset scroll with timing for Chrome
  setTimeout(() => window.scrollTo(0, 0), 25)

  const tl = gsap.timeline({
      delay: 0.2,
      onComplete: () => {
        // Update firstLoad variable
        globalStorage.firstLoad = false
      }
    })

    tl.add(pageIntro(), 0)

    return tl
}
