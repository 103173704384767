import { domStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { pageIntro } from '../animations/page-intro'

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    console.log('enter', domStorage.page_transition.querySelector('.Icon'))

    const tl = gsap.timeline({
      onComplete: () => done()
    })

    tl.to(domStorage.loader, { opacity: 0 }, 0)
    
    tl.to(domStorage.page_transition, { 
      clipPath: 'inset(0% 0% 100% 0%)',
      duration: 1.5, 
      ease: 'expo.inOut' 
    }, 0)

    tl.to(domStorage.page_transition.querySelector('.Icon.--rosace'), { 
      scale: 0,
      opacity: 0, 
      rotate: 180, 
      duration: 1.5, 
      yPercent: 50,
      ease: 'expo.inOut' 
    }, 0)
    
    tl.add(pageIntro(0))

    return tl
  }

  onLeave({ from, trigger, done }) {
    console.log('leave', domStorage.page_transition.querySelector('.Icon'))

    const tl = gsap.timeline({
      defaults: { duration: 0.4, ease: 'power4.in' },
      onStart: () => {
        domStorage.body.classList.add('--loading')
        domStorage.body.classList.add('--animating')
      },
      onComplete: () => done()
    })

    tl.fromTo(domStorage.loader, { 
      opacity: 0 
    }, { 
      opacity: 1 
    }, 0)

    tl.to(domStorage.header, { 
      yPercent: -30, 
      opacity: 0, 
      ease: 'expo.out', 
      duration: 1 
    }, 0)

    tl.fromTo(domStorage.page_transition, { 
      clipPath: 'inset(100% 0% 0% 0%)'
    }, { 
      clipPath: 'inset(0% 0% 0% 0%)',
      duration: 1.5, 
      ease: 'expo.inOut' 
    }, 0.5)

    tl.fromTo(domStorage.page_transition.querySelector('.Icon.--rosace'), {
      scale: 0,
      opacity: 0, 
      rotate: -180, 
      yPercent: -50,
    }, {
      yPercent: 0,
      scale: 1.5,
      opacity: 1, 
      rotate: 0,
      duration: 1.5, 
      ease: 'expo.inOut' 
    }, 0.5)
    
    return tl
  }
}
