import { Renderer } from '@unseenco/taxi'
import { isDEVMODE, viewStorage } from '../_globals'
import HistoryTimeline from '../animations/history-timeline'

export default class PageHistory extends Renderer {
  initialLoad() {
    document.addEventListener('DOMContentLoaded', () => {
      window.addEventListener('load', () => this.onEnter());
    })
  }

  onEnter() {
    this.DOM = {
      timelineSections: viewStorage.current.querySelectorAll('.TimelineSection')
    }

    if (this.DOM.timelineSections && this.DOM.timelineSections.length) this.timelineModule = new HistoryTimeline({ sections: this.DOM.timelineSections })
  }

  onLeaveCompleted() {
    if (this.timelineModule) this.timelineModule.destroy()
  }
}
